<template>
  <b-card title="Listar Prioridades">

    <listarPrioridades />
  </b-card>
</template>

<script>
// import axios from 'axios'
import {
  BCard,
} from 'bootstrap-vue'
import listarPrioridades from '../../Listagens/Prioridades/ListarPrioridades.vue'

export default {
  components: {
    BCard, listarPrioridades,
  },
}
</script>
